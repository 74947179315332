import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class App extends Component {
  constructor(props) {
    super();

    this.state = {
      contact: 'Contact',
      services: 'Animation Services',
      about: 'About',
      more: 'Bonus clip',
      btn1: 'btn-dark',
      btn2: 'text-primary',
      btn3: 'btn-dark',
      btn4: 'btn-dark',
      btn5: 'text-secondary sm',
      clients: 'Clients',
      anim: './skarvSnurr_v20.gif',
    };
    // this.onContact = this.onContact.bind(this);
    // this.onService = this.onService.bind(this);
    // this.onAbout = this.onAbout.bind(this);
    // this.onClients = this.onClients.bind(this);
    this.onServices = this.onServices.bind(this);
  }

  // onService() {
  //   console.log('hi!');
  //   let serv = 'Character Rigging, Animation and Compositing for film, broadcast, web and educational';
  //   this.setState({ services: serv, btn1: 'btn-outline-secondary' });
  // }

  onServices() {
    let services = (
      <div>
        Digital 2D Character Animation{' '}
        <i className={`${this.state.btn5}`}>
          Adobe After Effects <br />
        </i>{' '}
        Stop-Motion
        <i className={`${this.state.btn5}`}>
          {' '}
          Dragonframe <br />{' '}
        </i>{' '}
        Production Pipeline
        <i className={`${this.state.btn5}`}> ExtendScript</i>
        <br />
        <br />
        <a href="mailto:animator@isakgjertsen.com" className="btn btn-secondary">
          Contact
        </a>
      </div>
    );
    this.setState({
      services: services,
    });
  }
  // onAbout() {
  //   console.log('hi!');
  //   let abot = 'Freelance animator located in Stockholm, Sweden.';
  //   this.setState({
  //     about: abot,
  //     btn2: 'btn-outline-secondary',
  //   });
  // }

  // onContact() {
  //   let str = 'animator@isakgjertsen.com';
  //   this.setState({
  //     btnShow: false,
  //     contact: str,
  //     btn4: 'btn-outline-secondary',
  //     btnDisplayNone: '',
  //     services: 'Animation Services',
  //   });
  // }

  // componentDidMount() {
  //   let pictures = ['./erle3.gif'];
  //   //

  //   pictures.forEach((picture) => {
  //     let img = new Image();
  //     img.src = picture.fileName;
  //   });
  // }

  render() {
    const isBtnShow = this.state.btnShow;
    let extraButton = '';

    if (isBtnShow) {
      extraButton = (
        <button onClick={this.onMore} className={`btn ${this.state.btn5} btn-block p-2`}>
          {this.state.more}
        </button>
      );
    }

    return (
      <div className="container-fluid bg-dark p-0">
        <img className="d-block w-100 mb-2" src={this.state.anim}></img>
        {/* <button onClick={this.onService} className={`btn ${this.state.btn1} btn-block p-2`}>
          {this.state.services}
        </button> */}

        <button onClick={this.onServices} className={`btn ${this.state.btn4} btn-block p-2`}>
          {this.state.services}
        </button>

        {/* <button onClick={this.onAbout} className={`btn ${this.state.btn2} btn-block p-2`}>
          {this.state.about}
        </button> */}
        {/* <button onClick={this.onClients} className={`btn ${this.state.btn3} btn-block p-2`}>
          {this.state.clients}
        </button> */}
        {/* <button onClick={this.onContact} className={`btn ${this.state.btn4} btn-block p-2`}>
          {this.state.contact}
        </button> */}
      </div>
    );
  }
}

export default App;
